<template>
  <div style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @ok="ok" @hidden="hidden">
      
      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn @click="createFolder()"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </template>
        <template v-if="canView()">
          <b-popover
            :target="`BTN_EDIT_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.edit')">
          </b-popover>
          <b-btn :disabled="disableEdit" :id="`BTN_EDIT_${id}`" @click="editFolder"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>  
        </template>
        <template  v-if="canDelete()">
          <b-popover
            :target="`BTN_DELETE_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.delete')">
          </b-popover>
          <b-btn :disabled="disableDelete" :id="`BTN_DELETE_${id}`" @click="removeDataview"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>  
        </template>
      </div>
      
      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="task-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :autoGroupColumnDef="autoGroupColumnDef"
            :getRowId="params => params.data.uuId"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :rowData="rowData"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            treeData
            :getDataPath="getDataPath"
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }" style="justify-content: start;">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
        
    <DataviewFolderModal :id="folderUuid" :visibility="visibility" :folderName="folderName" :parentData="folderParentData" :show.sync="createFolderDataviewShow" @success="createFolderModalOk"/>
    
    <b-modal :title="$t('task.confirmation.title_delete')"
        v-model="confirmDeleteDataviewShow"
        :ok-title="$t('button.confirm')"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="confirmDeleteDataviewOk"
        >
      <div class="d-block">
        {{ $t('dataview.confirmation.delete_folder') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmDeleteDataviewOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import DurationCellRenderer from '@/components/Aggrid/CellRenderer/Duration';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import { viewProfileService, companyService, dataviewProfileService } from '@/services';
import { EventBus, strRandom } from '@/helpers';

export default {
  name: 'DataviewFolderSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    DataviewFolderModal: () => import('@/components/modal/DataviewFolderModal'),
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'percentageCellRenderer': PercentageCellRenderer,
    'durationCellRenderer': DurationCellRenderer,
    'detailLinkCellRenderer': DetailLinkCellRenderer
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    preselected: {
      type: String,
      default: null
    },
    visibility: {
      type: String,
      default: 'private'
    },
    dataviewUuid: {
      type: String,
      default: null
    },
    exclude: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      id: `DATAVIEW_FOLDER_LIST_${strRandom(5)}`,
      permissionName: 'TASK',
      userId: null,
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      modalShow: false,
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,

      selected: [],
      
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,
      
      searchFilter: '',
      disableOk: true,
      selectDataviewShow: false,
      dataviewComponent: null,
      dataviewOptions: [],
      dataview: null,
      folders: {},
      restoreSelection: null,
      createFolderDataviewShow: false,
      folderName: null,
      folderUuid: null,
      folderParentData: {
        name: null,
        uuId: null
      },
      
      confirmDeleteShow: false,
      confirmDeleteDataviewShow: false,
      privateDataViews: [],
      disableDelete: false,
      disableEdit: false
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const profileKey = 'task_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.gridOptions = {  
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onSortChanged: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.api.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
        setTimeout(() => {
          self.processNodes();
        }, 100);
      }
    };
    this.setColumnDefs();
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.userId = this.$store.state.authentication.user.uuId;
    this.updateModalShow(this.show);
    const self = this;
    EventBus.$on('folder-created', async () => {
      self.reloadData();
    });
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
    EventBus.$off('folder-created');
    this.gridApi = null;
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        this.dataviewComponent = null;
        await this.loadViewProfile();
        
      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('dataview.no_folders') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('task.title_selector');
    }
  },
  methods: {
    processNodes() {
      const self = this;
      this.gridApi.forEachNode((node, b) => {
        if (node.id === self.restoreSelection) {
          node.setSelected(true);
          node.setExpanded(true);
          while (node.parent) {
            node.parent.setExpanded(true);
            node = node.parent;
          }
          self.restoreSelection = null;
        }
        node.setExpanded(true); // expand all folders
      });
    },
    async loadDataviews() {
      const userId = this.$store.state.authentication.user.uuId;
      const ret = [];
      if (this.visibility === 'private') {
        const profiles = await this.$store.dispatch('data/dataViewProfileList', userId).then((response) => {
          const profileData = response;
          const privateProfiles = profileData.filter(f => f.sharedVisibility !== 'public').sort(function (a, b) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          return privateProfiles;
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return [];
        });
        
        this.privateDataViews = profiles;
        return this.pruneTree(profiles.filter(p => p.type === 'folder'), this.exclude)
      }
      else {  
        // Get the public data views
        if (!localStorage.companyId) {
          const companyUuid = await companyService.list({ limit: -1, start: 0 }).then((response) => {
            const data = response.data;
            const company = data.filter(d => d.type === 'Primary');
            if (company.length > 0) {
              return company[0].uuId;
            }
          })
          .catch((e) => {
            console.log(e); // eslint-disable-line no-console
            return null;
          });
          
          localStorage.companyId = companyUuid;
          const pubProfiles = await this.$store.dispatch('data/dataViewProfileListPublic', companyUuid).then((response) => {
            const profileData = response.sort(function (a, b) {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            return profileData;
          })
          .catch((e) => {
            console.log(e); // eslint-disable-line no-console
            return [];
          });
          return this.pruneTree(pubProfiles.filter(p => p.type === 'folder'), this.exclude);
        }
        else {
          const pubProfiles = await this.$store.dispatch('data/dataViewProfileListPublic', localStorage.companyId).then((response) => {
            const profileData = response.sort(function (a, b) {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            return profileData;
          })
          .catch((e) => {
            localStorage.removeItem('companyId');
            console.log(e); // eslint-disable-line no-console
            return [];
          });
          return this.pruneTree(pubProfiles.filter(p => p.type === 'folder'), this.exclude);
        }
      }
    },
    pruneTree(data, uuId) {
      const ret = [];
      for (const d of data) {
        const path = this.getDataPath(d);
        if (!path.includes(uuId)) {
          ret.push(d);
        }
      }
      return ret; 
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.restoreSelection = this.preselected;
      this.reloadData();
    },
    async reloadData() {
      this.saveNodeState();
      this.rowData = await this.loadDataviews();
      this.folders = this.rowData.reduce(function(map, obj) {
          map[obj.uuId] = obj;
          return map;
      }, {});
      
      setTimeout(() => {
        this.restoreNodeState();
      }, 100);
    },
    saveNodeState() {
      this.nodeState = {};
      if (this.gridApi) {
        this.gridApi.forEachNode(node => {
          this.nodeState[node.id] = { expanded: node.expanded, selected: node.selected };
        });
      }
    },
    restoreNodeState() {
      if (this.gridApi && this.nodeState) {
        this.gridApi.forEachNode(node => {
          if (node.id in this.nodeState) {
            node.setExpanded(this.nodeState[node.id].expanded);
            node.setSelected(this.nodeState[node.id].selected);
          }
        });
        this.nodeState = {};
      }
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
      if (newValue) {
        this.setColumnDefs();
      }
    },
    handleRowHovered(item, index, event) {
      event.target.lastElementChild.classList.toggle('d-none');
      event.target.lastElementChild.classList.toggle('d-flex');
    },
    handleRowUnhovered(item, index, event) {
      event.target.lastElementChild.classList.toggle('d-none');
      event.target.lastElementChild.classList.toggle('d-flex');
    },
    updateChildNodes() {
      if (this.gridApi.getSelectedNodes().length === 0) {
        this.hasChildNodes = false;
      }
      else {
        const node = this.gridApi.getSelectedNodes()[0];
        this.hasChildNodes = typeof node.group !== 'undefined';
      }
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    ok() {
      const details = this.gridApi.getSelectedNodes();
      this.$emit('ok', details[0].data);
   
    },
    hidden() {
      this.alert = null;
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.task_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.task_selector_list);
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = false;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridApi != null) {
        data.gridApi.setGridOption('columnDefs', []);
        data.gridApi.setGridOption('columnDefs', data.columnDefs);
      }
      return false;
    },
    fileImport() {
      this.docImportShow = true;
    },
    processCellCallback(self) {
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        }
        return params.value;
      }
    },
    createFolder() {
      const details = this.gridApi.getSelectedNodes();
      this.folderName = null;
      this.folderUuid = `DATAVIEW_FOLDER_NEW_${strRandom(5)}`;
      this.folderParentData = { 
        name: details.length === 1 ? details[0].data.name : null,
        uuId: details.length === 1 ? details[0].data.uuId : null
      }
      this.createFolderDataviewShow = true;
    },
    editFolder() {
      const details = this.gridApi.getSelectedNodes();
      this.folderName = details[0].data.name;
      this.folderUuid = details[0].data.uuId;
      this.folderParentData = {
        name: details[0].parent.data ? details[0].parent.data.name : null,
        uuId: details[0].parent.data ? details[0].parent.data.uuId : null
      }
      this.createFolderDataviewShow = true;
    },
    openDetail(name, params) {
      this.folderName = params.data.name;
      this.folderUuid = params.data.uuId;
      this.folderParentData = {
        name: params.node.parent.data ? params.node.parent.data.name : null,
        uuId: params.node.parent.data? params.node.parent.data.uuId : null
      }
      this.createFolderDataviewShow = true;
    },
    detailLinkLabel(params) {
      if (params.data) {
        return params.data.name;
      }
      return 'N/A';
    },
    removeDataview() {
      this.confirmDeleteDataviewShow = true;
    },
    async unshareDataviewProfile(dvId, shareToId) {
        await dataviewProfileService.unshare(dvId, shareToId, this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async deleteDataviewProfile(uuId) {
      const self = this;
      await dataviewProfileService.remove([{ uuId: uuId }],
                        this.userId).then((response) => {  
        self.profileData = response.data;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }, 
    async getCompany() {
      const company = await companyService.list({limit: -1, start: 0}).then((response) => {
        const data = response.data;
        return data.filter(d => d.type === 'Primary')[0];
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });
      return company;
    },
    async confirmDeleteDataviewOk() {
      let company = null;
      let deletedThis = false;
      
      const details = this.gridApi.getSelectedNodes();
      // delete folders and their contents
      for (const selected of details) {
        const folderUuid = selected.data.uuId;
        await this.deleteDataviewProfile(folderUuid);
        for (const dv of this.privateDataViews.filter(d => this.getDataPath(d).includes(folderUuid))) {
          if (dv.sharedVisibility === 'public') {
            if (company === null) {
              company = await this.getCompany();
              
              if (company) {
                await this.unshareDataviewProfile(dv.uuId, company.uuId);
              }
            }
          }
          
          const existingIds = typeof dv.sharingMembers !== 'undefined' &&
                              dv.sharingMembers !== ""
                               ? dv.sharingMembers.split(',') : [];
          // remove unshared
          for (let existingId of existingIds) {
            if (existingId !== this.userId) {
              await this.unshareDataviewProfile(dv.uuId, existingId);
            }
          } 
          
          if (dv.uuId === this.dataviewUuid) {
            deletedThis = true;
          }
          await this.deleteDataviewProfile(dv.uuId);
        }
      }
      EventBus.$emit('dataview-reload');
      this.rowData = await this.loadDataviews();
      this.confirmDeleteDataviewShow = false;
      if (deletedThis) {
        EventBus.$emit('dataview-top-group');
        // If this data view was deleted, go to the dashboard
        setTimeout(() => {
          this.$router.push('/dashboard');
        }, 10);
      }
    },
    async shareDataviewProfile(uuId, shareToId, isPublic = false) {
        await dataviewProfileService.share(uuId, shareToId, isPublic).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    async createFolderModalOk({folderUuid, folderName, parentUuid, parentName}) {
      // update
      if (folderUuid) {
        await dataviewProfileService.update([{ uuId: folderUuid, name: folderName, type: 'folder', sharedVisibility: this.visibility, parent: parentUuid }],
                          this.userId).then((response) => {  
          const data = response.data[response.data.jobCase];
          return data[0];
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return null;
        });
      }
      else { // create
        const uuId = await dataviewProfileService.create([{ name: folderName, type: 'folder', parent: parentUuid, sharedVisibility: this.visibility }],
                          this.userId).then((response) => {  
          const data = response.data[response.data.jobCase];
          return data[0].uuId;
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return null;
        });
        
        const company = await this.getCompany();
        if (this.visibility === 'public') {
          await this.shareDataviewProfile(uuId, company.uuId, true);
        }
      }
      this.createFolderDataviewShow = false;
      // clear the cache and get the latest data
      const userId = this.$store.state.authentication.user.uuId;
      this.$store.dispatch('data/clearDataViewProfileCache', userId, localStorage.companyId);
 
      EventBus.$emit('dataview-reload');
      this.rowData = [];
      this.$nextTick(() => {
        this.reloadData();
      });
    },
    getDataPath(data) {
      const ret = [data.uuId];
      let parent = data.parent;
      while (parent) {
        ret.unshift(parent);
        parent = this.folders[parent] ? this.folders[parent].parent : null;
      }
      return ret;
    },
    setColumnDefs() {
      this.columnDefs = [
      ];
      this.defaultColDef = {
        sortable: true,
        resizable: true,
        minWidth: 100,
        hide: true,
        menuTabs: ['columnsMenuTab']
      };
      this.autoGroupColumnDef = {
        headerName: this.$t('dataview.field.name'),
        field: 'name',
        width: 750,
        cellRendererParams: { 
          checkbox: true,
          comparator: (valueA, valueB) => {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          },
          innerRenderer: 'detailLinkCellRenderer',
          suppressCount: true 
        }
      };
      this.context = {
        componentParent: this
      };
    }
  }
}


</script>